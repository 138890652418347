import { styled } from '@mui/material/styles';

export const TotalsCellContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  paddingRight: theme.spacing(0.5),
  width: '100%',
  gap: 15,
}));

import { GRID_DETAIL_PANEL_TOGGLE_COL_DEF, GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';

import {
  VenueNameCell,
  LeftPinnedCell,
  ExpandButtonCell,
  TextWrapPrettyCell,
  EventOfferActionsCell,
} from '@components';
import { leftPinnedColumn } from '@utils/datagridHelpers';
import { BonusAllocation, BonusAllocationEventOffer } from '@types';
import { BonusAllocationCell } from '../cells/BonusAllocationCell/BonusAllocationCell';

export type BonusAllocationRow = (
  BonusAllocation
  & BonusAllocationEventOffer
  & { isBottomRow?: boolean; isTotalsRow?: boolean }
);
type BonusAllocationColumns = GridColDef<BonusAllocationRow>[];
type RenderCellParams = GridRenderCellParams<BonusAllocationRow>;

const aegPLColumn = {
  field: 'aegPL',
  headerName: 'AEG P&L',
  flex: 0.25,
  minWidth: 270,
  cellClassName: 'disabled-cell',
  renderCell: ({ row, field }: RenderCellParams) => (
    <BonusAllocationCell
      field={field}
      name={row.name}
      activeOffer={row}
      status={row.status}
      totalActiveOffer={row.totalActiveOffers}
      disabled
    />
  ),
};

const localOfferBonusColumn = {
  field: 'localOfferBonus',
  headerName: 'Local Offer Bonus',
  flex: 0.2,
  minWidth: 160,
  cellClassName: 'disabled-cell',
  renderCell: ({ row, field }: RenderCellParams) => (
    <BonusAllocationCell
      field={field}
      name={row.name}
      activeOffer={row}
      status={row.status}
      totalActiveOffer={row.totalActiveOffers}
      disabled
    />
  ),
};

const bonusAmountColumn = {
  field: 'bonusAmount',
  headerName: 'Bonus Amount',
  flex: 0.3,
  minWidth: 265,
  renderCell: ({ row, field }: RenderCellParams) => (
    <BonusAllocationCell
      placeholder='Enter value'
      field={field}
      name={row.name}
      activeOffer={row}
      status={row.status}
      totalActiveOffer={row.totalActiveOffers}
    />
  ),
};

const internalNotesColumn = {
  field: 'note',
  headerName: 'Internal Notes',
  flex: 0.4,
  minWidth: 170,
  renderCell: ({ row, field }: RenderCellParams) => (
    <BonusAllocationCell
      placeholder='Write a note'
      field={field}
      name={row.name}
      activeOffer={row}
      status={row.status}
      totalActiveOffer={row.totalActiveOffers}
    />
  ),
};

const renderVenueNameCell = ({ row }: RenderCellParams) => (
  <LeftPinnedCell>
    <VenueNameCell venue={row.venue} />
    <EventOfferActionsCell
      updatedDate={row.updatedDate}
      offerId={row.offerId}
    />
  </LeftPinnedCell>
);

export const bonusAllocationColumns: BonusAllocationColumns = [
  {
    ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    renderCell: ExpandButtonCell,
  },
  {
    ...leftPinnedColumn,
    field: 'name',
    headerName: 'Active Offers',
    renderCell: (props) => {
      const { row } = props;
      if (row.isBottomRow) {
        return renderVenueNameCell(props);
      }

      return (
        <LeftPinnedCell>
          <TextWrapPrettyCell fontWeight={row.isTotalsRow ? '500' : 'normal'}>{row.name}</TextWrapPrettyCell>
        </LeftPinnedCell>
      );
    },
  },
  aegPLColumn,
  localOfferBonusColumn,
  bonusAmountColumn,
  internalNotesColumn,
];

export const bonusAllocationEventOfferColumns: BonusAllocationColumns = [
  {
    ...leftPinnedColumn,
    field: 'venueName',
    colSpan: 2,
    renderCell: renderVenueNameCell,
  },
  {
    field: 'whiteSpace',
    width: 10,
  },
  aegPLColumn,
  localOfferBonusColumn,
  bonusAmountColumn,
  internalNotesColumn,
];

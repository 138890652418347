import Lock from '@mui/icons-material/Lock';
import LockOpen from '@mui/icons-material/LockOpenOutlined';

interface LockIconProps {
  locked?: boolean;
  onChange?: (locked: boolean) => void;
}

export function LockIcon({ locked = false, onChange }: LockIconProps) {
  const Icon = locked ? Lock : LockOpen;

  return (
    <Icon
      color='action'
      fontSize='small'
      data-testid={locked ? 'lock-icon' : 'unlock-icon'}
      onClick={() => onChange?.(!locked)}
    />
  );
}

import { Venue } from '@gql/types/graphql';

export interface BonusAllocation {
  name: string;
  activeOffers: BonusAllocationEventOffer[];
  totalActiveOffers: BonusAllocationEventOffersSumary;
}

export type BonusAllocationEventOffer = EventOfferBase & CalculatedColumns;
export interface BonusAllocationEventOffersSumary extends CalculatedColumns {
  bonusAllocations: EventOfferBonusAllocation[];
  locked: boolean;
  note: string;
}

export enum BonusAllocationType {
  Fixed = 'Fixed',
  Percent = 'Percent',
}

/* ----- Data from GQL ----- */
interface EventOfferBase {
  id: string;
  bundleId: string;
  partitionKey: string;
  eventId: string;
  offerId: string;
  status: string;
  note: string;
  settlementId: string;
  updatedDate: string;
  venue: Venue;
  locked: boolean;
  bonusAllocations: EventOfferBonusAllocation[]
}

interface EventOfferBonusAllocation {
  type: BonusAllocationType | null;
  amount: number | null;
}
/* ------------------------- */

/* ---- Calculated data ---- */
interface CalculatedColumns {
  aegPL: number;
  localOfferBonus: number | null;
}
/* ------------------------- */

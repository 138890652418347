import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import { capitalize } from '@mui/material/utils';

import { SelectCell } from '../Cells.styled';
import { FormattedNumberFieldCell } from '../FormattedNumberFieldCell/FormattedNumberFieldCell';
import { ValueTypeCellContainer } from './ValueTypeCell.styled';

interface ValueTypeCellProps {
  value?: string;
  onChangeValue?: (value: string) => void;
  onSelectType?: (type: string) => void;
  formatValue?: (value: string) => string;
  type?: string;
  options: {
    label: string;
    value: string;
  }[];
  disabled?: boolean;
}

// eslint-disable-next-line max-lines-per-function
export function ValueTypeCell({
  value,
  type,
  options,
  disabled,
  formatValue,
  onChangeValue,
  onSelectType,
}: ValueTypeCellProps) {
  const typeNotInOptions = !options.some(
    (option) => option.value === type,
  ); // i.e. 'Varies' or 'Manual'

  return (
    <ValueTypeCellContainer>
      <FormattedNumberFieldCell
        isPercentage
        value={value}
        formatValue={formatValue}
        onChangeValue={onChangeValue}
        disabled={disabled}
      />
      <SelectCell
        variant="standard"
        displayEmpty
        value={type}
        onChange={({ target }) => onSelectType?.(target.value as string)}
        disabled={disabled || !onSelectType}
      >
        <MenuItem disabled value={typeNotInOptions ? type : ''}>
          {typeNotInOptions ? capitalize(type ?? '') : 'Enter amount'}
        </MenuItem>
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </SelectCell>
    </ValueTypeCellContainer>
  );
}

/* eslint-disable max-lines-per-function */
import {
  DetailsMetadataItem,
  SelectWithPrimary,
  SelectWithPrimaryOptions,
  ProtectedComponent,
  StatusChip,
  MetadataObject,
  KebabPopperMenu,
  ExcelButton,
  SnackbarType,
  FeatureGate,
} from '@components';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { NotificationDispatchContext } from '@providers';
import React, { useContext, useEffect } from 'react';
import {
  Artist,
  Offer,
  WorkbookStatusType,
} from '@gql/types/graphql';
import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import { LAUNCH_EXCEL } from '@gql/mutations/tours';
import {
  AegResourceTypes, ExcelWorkbookLaunchMode, UserPermissions, LineOfBusiness,
} from '@types';
import { getConfigurationTypeLabel } from '@utils/configurationTypeHelpers';
import { getExcelWorkbookLaunchUrl } from '@utils/stringHelpers';
import CheckCircle from '@mui/icons-material/CheckCircle';
import Error from '@mui/icons-material/Error';
import { useCallbackOnWindowFocus, useFeatureFlags } from '@hooks';
import FeatureFlags from '@utils/featureFlags';
import {
  GET_WORKBOOK_VERSION_INFO, GET_PREV_WORKBOOK_DOWNLOAD_URL, GET_MODELING_DOWNLOAD_URL,
} from '@gql/queries/excel';
import { GET_TOUR_OFFER_REFRESH_STATUS } from '@gql/queries/tours';
import dayjs from 'dayjs';
import { downloadFile } from '@utils/downloadHelpers';
import {
  ExcelButtonContainer,
  MetadataGrid,
  OfferButtons,
  OfferMenuContainer,
  OfferSelectContainer,
  OfferVersion,
  ToolTipTextContainer,
} from './TourSummaryHeader.styled';
import { SnackBarMessages } from './TourSummaryHeader.options';

export interface TourSummaryHeaderProps {
  tourId: string;
  tourName: string;
  offer: Offer | null;
  offerItems: SelectWithPrimaryOptions[];
  offerSelectValue?: string;
  offerChange: (offerId: string) => void;
  handleCloneOffer: () => void;
  handleEditOffer: () => void;
  isOfferDraft: boolean;
  isPrimaryOfferDisabled: boolean;
  updatePrimaryOffer: (offerId: string, name: string) => Promise<void>;
}

export const TourSummaryHeader: React.FC<TourSummaryHeaderProps> = ({
  tourId,
  tourName,
  offer,
  offerSelectValue,
  offerItems,
  offerChange,
  handleEditOffer,
  handleCloneOffer,
  isOfferDraft,
  isPrimaryOfferDisabled,
  updatePrimaryOffer,
}) => {
  const featureFlags = useFeatureFlags();
  const setNotification = useContext(NotificationDispatchContext);
  const canRefreshWorkbook = featureFlags.getFlag(FeatureFlags.CAN_REFRESH_WORKBOOK);
  const [launchExcelWorkbook, { loading }] = useMutation(LAUNCH_EXCEL);

  // ----- Workbook Version Info -----
  const { data: workbookInfo, refetch: refetchWorkbookInfo } = useQuery(GET_WORKBOOK_VERSION_INFO, {
    variables: {
      tourId,
      offerId: offer?.id as string,
    },
    skip: !canRefreshWorkbook,
  });
  useCallbackOnWindowFocus(async () => {
    if (!canRefreshWorkbook) return;
    await refetchWorkbookInfo();
  });
  // ---------------------------------

  // ---- Workbook Refresh Status -----
  const {
    data: workbookRefreshStatusData,
    refetch: refetchWorkbookRefreshStatus,
    startPolling: startPollingWorkbookRefreshStatus,
    stopPolling: stopPollingWorkbookRefreshStatus,
  } = useQuery(
    GET_TOUR_OFFER_REFRESH_STATUS,
    {
      variables: {
        tourId,
        offerId: offer?.id as string,
      },
      skip: !canRefreshWorkbook,
    },
  );
  useCallbackOnWindowFocus(async () => {
    if (!canRefreshWorkbook) return;
    await refetchWorkbookRefreshStatus();
  });

  const workbookRefreshStatus = workbookRefreshStatusData?.tour?.offers?.[0]?.workbookRefreshStatus;
  useEffect(() => {
    if (workbookRefreshStatus?.status === WorkbookStatusType.InProgress) {
      startPollingWorkbookRefreshStatus(500);
    } else {
      stopPollingWorkbookRefreshStatus();
      void refetchWorkbookInfo();
    }
  }, [workbookRefreshStatus?.status]);
  // ---------------------------------

  // ---- Previous Workbook Download -----
  const [
    getPrevWorkbookDownloadUrl, { loading: prevWorkbookDownloadLoading },
  ] = useLazyQuery(GET_PREV_WORKBOOK_DOWNLOAD_URL);

  const downloadPrevWorkbookFile = async () => {
    const downloadUrlResponse = await getPrevWorkbookDownloadUrl({
      variables: {
        tourId,
        offerId: offer?.id as string,
      },
      onError: () => {
        setNotification({
          type: SnackbarType.ERROR,
          text: SnackBarMessages.savePreviousWorkbookError,
          duration: 6000,
        });
      },
    });

    const url = downloadUrlResponse?.data?.getPreviousWorkbookDownloadUrl?.url;
    if (url) {
      window.location.href = url;
      setNotification({
        type: SnackbarType.SUCCESS,
        text: SnackBarMessages.savePreviousWorkbookSuccess,
        duration: 6000,
      });
    }
  };
  // ---------------------------------

  // ----- Modeling Workbook Download -----
  const [getModelingDownloadUrl, { loading: modelingDownloadLoading }] = useLazyQuery(GET_MODELING_DOWNLOAD_URL);

  const downloadModelingFile = async () => {
    const downloadUrlResponse = await getModelingDownloadUrl({
      variables: {
        tourId,
        offerId: offer?.id as string,
      },
      onError: () => {
        setNotification({
          type: SnackbarType.ERROR,
          text: SnackBarMessages.saveWorkbookCopyError,
          duration: 6000,
        });
      },
    });

    const url = downloadUrlResponse?.data?.getModelingDownloadUrl?.url;
    if (url) {
      const response = await fetch(url, { mode: 'cors' });
      const blob = await response.blob();
      downloadFile(blob, `${tourName} - ${offer?.name as string} - as_of_${dayjs().format('MM.DD.YY')}.xlsm`);
      setNotification({
        type: SnackbarType.SUCCESS,
        text: SnackBarMessages.saveWorkbookCopySuccess,
        duration: 6000,
      });
    }
  };
  // ---------------------------------

  const launchExcel = async () => {
    if (offer) {
      const response = await launchExcelWorkbook({
        variables: {
          launchExcel: {
            tourId,
            offerId: offer.id as string,
            mode: ExcelWorkbookLaunchMode.MODELING,
          },
        },
      });
      window.location.href = getExcelWorkbookLaunchUrl(response.data?.launchExcelWorkbook?.url as string);
    }
  };

  const setPrimaryOffer = async () => {
    const offerId = offer?.id as string;
    const name = offer?.name as string;
    await updatePrimaryOffer(offerId, name);
  };

  const getCrossedDisplayLabel = (): string => {
    if (offer?.isCrossed !== undefined) {
      return offer?.isCrossed === true ? 'Yes' : 'No';
    }
    return '';
  };

  const metadata: MetadataObject[] = [];
  metadata.push({
    title: 'Offer Status',
    element: <StatusChip status={offer?.status || 'Draft'}/>,
  });
  metadata.push({
    title: 'Supporting Artist',
    value:
      offer && offer.supports?.length
        ? offer.supports
          .filter((support): support is Artist => support !== null)
          .map((support) => support.name || '')
        : [],
  });
  metadata.push({
    title: 'Configuration',
    value: getConfigurationTypeLabel(offer?.configuration),
  });
  metadata.push({
    title: 'Crossed',
    value: String(getCrossedDisplayLabel()),
  });
  metadata.push({
    title: 'Trucks',
    value: (offer && offer.numberOfTrucks?.toString()) || '0',
  });
  metadata.push({
    title: 'Events',
    value: (offer && offer.events?.length.toString()) || '0',
  });

  let offerVersionDetail = null;

  // Show workbook as up to date if current version OR version >= 2.0 and notify flag set to false
  if (workbookRefreshStatus?.status === WorkbookStatusType.Failed) {
    offerVersionDetail = (
      <>
        <Typography>Out of Date</Typography>
        <Tooltip
            arrow
            title={(
              <ToolTipTextContainer>
                <span>Update failed</span>
                <br />
                <span>Launch Offer to try again</span>
              </ToolTipTextContainer>
            )}
          >
            <Error color="error" data-testid="workbook-refresh-error-icon" />
        </Tooltip>
      </>
    );
  } else if (workbookInfo?.getVersionInformation?.isCurrent
    || (workbookInfo?.getVersionInformation?.version // i.e., workbook version >= v2.0
      && !workbookInfo?.getVersionInformation?.notify
    )
  ) {
    offerVersionDetail = (
      <>
        <Typography>{workbookInfo?.getVersionInformation?.version}</Typography>
        <Tooltip
          arrow
          title={
            <ToolTipTextContainer>
              Up To Date
            </ToolTipTextContainer>
          }>
          <CheckCircle color="success" />
        </Tooltip>
      </>
    );
  } else if (workbookInfo?.getVersionInformation?.isCurrent === false) {
    offerVersionDetail = (
      <>
        <Typography>Out of Date</Typography>
        <Tooltip
          arrow
          title={
            <ToolTipTextContainer>
              <span>Update available</span>
              <br />
              <span>Launch Offer to update</span>
            </ToolTipTextContainer>
          }
        >
          <Error color="warning" />
        </Tooltip>
      </>
    );
  }

  if (offerVersionDetail) {
    metadata.push({
      title: 'Offer Template',
      element: (
        <OfferVersion>
          {offerVersionDetail}
        </OfferVersion>
      ),
    });
  }

  const metadataRowItems = metadata.map((metadataItem, index) => (
    <DetailsMetadataItem
      key={index}
      metadata={metadataItem}
      first={index === 0}
    />
  ));

  return (
    <Grid container>
      <Grid item xs={2.5} data-testid="offer-dropdown-container">
        <OfferSelectContainer>
          <SelectWithPrimary
            value={
              offerItems.find((item) => item.value === offerSelectValue)
                ?.value || undefined
            }
            items={offerItems}
            label="Offers"
            onChange={offerChange}
            lineOfBusiness={LineOfBusiness.TOURS}
          ></SelectWithPrimary>
        </OfferSelectContainer>
      </Grid>
      <MetadataGrid
        item
        xs={true}
        container
        spacing={5}
        data-testid="offer-metadata"
      >
        {metadataRowItems}
      </MetadataGrid>
      <OfferButtons>
        <ExcelButtonContainer>
          <ExcelButton
            launchExcel={launchExcel}
            loading={loading || workbookRefreshStatus?.status === WorkbookStatusType.InProgress}
            label={
              workbookRefreshStatus?.status === WorkbookStatusType.InProgress
                ? 'Offer Updating'
                : 'Launch Offer'
            }
          />
        </ExcelButtonContainer>
        <ProtectedComponent
          checkPermission={{
            permission: UserPermissions.UpdateTour,
            resourceType: AegResourceTypes.Tour,
            resourceId: tourId,
          }}
        >
          <OfferMenuContainer>
            <KebabPopperMenu>
              <MenuItem onClick={handleEditOffer}>
                Edit Offer
              </MenuItem>
              <MenuItem
                onClick={handleCloneOffer}
                disabled={!isOfferDraft}
              >
                Duplicate Offer
              </MenuItem>
              <MenuItem
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onClick={setPrimaryOffer}
                disabled={isPrimaryOfferDisabled}
                data-testid="select-primary-button"
              >
                Set as Primary
              </MenuItem>
              <FeatureGate configFlag={FeatureFlags.CAN_REFRESH_WORKBOOK}>
                <MenuItem
                  onClick={() => {
                    void downloadPrevWorkbookFile();
                  }}
                  data-testid="download-previous-workbook-button"
                  disabled={
                    prevWorkbookDownloadLoading
                    || !offer?.previousWorkbookDetails?.slice(-1)[0]?.workbookFileId
                    || workbookRefreshStatus?.status === WorkbookStatusType.InProgress
                  }
                >
                  Save Previous Offer Version
                </MenuItem>
              </FeatureGate>
              <MenuItem
                onClick={() => {
                  void downloadModelingFile();
                }}
                data-testid="download-modeling-button"
                disabled={modelingDownloadLoading || !offer?.workbookFileId}
              >
                Save Workbook Copy
              </MenuItem>
            </KebabPopperMenu>
          </OfferMenuContainer>
        </ProtectedComponent>
      </OfferButtons>
    </Grid>
  );
};

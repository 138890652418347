import React, { useState } from 'react';

import { TextFieldCell } from '../Cells.styled';

interface ValueCellProps {
  value?: string;
  colorCodeNegative?: boolean;
  onChangeValue?: (value: string) => void;
  formatValue?: (value: string) => string;
  disabled?: boolean;
  isPercentage?: boolean;
  placeholder?: string;
}

export function FormattedNumberFieldCell({
  value,
  disabled,
  colorCodeNegative,
  placeholder = 'Enter value',
  isPercentage = false,
  formatValue,
  onChangeValue,
}: ValueCellProps) {
  const [inputValue, setInputValue] = useState<string>();
  const [valueFocused, setValueFocused] = useState(false);
  const onFocus = () => {
    const safeValue = Number.isNaN(Number(value)) ? '' : value;
    setInputValue(safeValue);
    setValueFocused(true);
  };
  const onBlur = () => setValueFocused(false);

  const handleChange = (targetValue: string) => {
    const cleanTargetValue = targetValue.replace('%', '');
    const numberValue = Number(cleanTargetValue);

    if (isPercentage && (Number.isNaN(numberValue) || numberValue < 0 || numberValue > 100)) {
      return;
    }

    setInputValue(targetValue);
    onChangeValue?.(cleanTargetValue);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation(); // Prevent MUI datagrid arrow keys from triggering
    if (e.key === 'Enter') {
      const target = e.target as HTMLInputElement;
      handleChange(target.value);
      target.blur();
    }
  };

  return (
    <TextFieldCell
      variant="standard"
      placeholder={placeholder}
      colorCodeNegative={colorCodeNegative}
      value={valueFocused
        ? inputValue // Use input value if focused
        : formatValue?.(value ?? '') // Otherwise use the formatted value passed in
      }
      onChange={(e) => handleChange(e.target.value)}
      disabled={disabled}
      onFocus={onFocus}
      onBlur={onBlur}
      onKeyDown={handleKeyDown}
    />
  );
}

import Divider from '@mui/material/Divider';

import {
  LockIcon,
  StatusChip,
  TextFieldCell,
  FormattedNumberFieldCell,
} from '@components';
import { formatNumberToCurrency, formatNumberToPercentage } from '@utils/numberHelpers';
import { BonusAllocationEventOffer, BonusAllocationEventOffersSumary, BonusAllocationType } from '@types';

import { TotalsCellContainer } from './BonusAllocationCell.styled';

interface BonusAllocationCellProps {
  name: string;
  field: string;
  status: string;
  activeOffer: BonusAllocationEventOffer;
  totalActiveOffer: BonusAllocationEventOffersSumary;
  disabled?: boolean;
  placeholder?: string;
}

// eslint-disable-next-line max-lines-per-function
export function BonusAllocationCell({
  name,
  activeOffer,
  totalActiveOffer,
  field,
  status,
  disabled,
  placeholder,
}: BonusAllocationCellProps) {
  if (name === 'Inactive Offers') {
    return <></>;
  }

  const colorCodeNegative = Math.sign(Number(totalActiveOffer?.aegPL ?? activeOffer.aegPL ?? 0)) === -1;

  if (field === 'bonusAmount') {
    const fixedAmount = (totalActiveOffer?.bonusAllocations ?? activeOffer.bonusAllocations).find(
      (allocation) => allocation.type === BonusAllocationType.Fixed,
    )?.amount;

    const percentAmount = (totalActiveOffer?.bonusAllocations ?? activeOffer.bonusAllocations).find(
      (allocation) => allocation.type === BonusAllocationType.Percent,
    )?.amount;

    return (
      <TotalsCellContainer>
        <LockIcon locked={totalActiveOffer?.locked ?? activeOffer.locked} />
        <FormattedNumberFieldCell
          value={fixedAmount?.toString() ?? ''}
          formatValue={(value) => {
            if (value === null || value === undefined || value === '') {
              return '';
            }
            return formatNumberToCurrency(Number(value), { nullishValue: '' });
          }}
          colorCodeNegative={colorCodeNegative}
          disabled={disabled}
        />
        <Divider orientation="vertical" variant="middle" flexItem />
        <FormattedNumberFieldCell
          isPercentage
          value={percentAmount?.toString() ?? ''}
          formatValue={(value) => {
            if (value === null || value === undefined || value === '') {
              return '';
            }
            return formatNumberToPercentage(Number(value));
          }}
          colorCodeNegative={colorCodeNegative}
          disabled={disabled}
        />
      </TotalsCellContainer>
    );
  }

  const value = totalActiveOffer?.[field as keyof BonusAllocationEventOffersSumary]
    ?? activeOffer[field as keyof BonusAllocationEventOffer];

  if (field === 'aegPL' || field === 'localOfferBonus') {
    return (
      <TotalsCellContainer>
        <FormattedNumberFieldCell
          value={value?.toString() ?? ''}
          formatValue={(valueToFormat) => {
            if (valueToFormat === null || valueToFormat === undefined || valueToFormat === '') {
              return '-';
            }
            return formatNumberToCurrency(Number(valueToFormat), { nullishValue: '' });
          }}
          colorCodeNegative={colorCodeNegative}
          placeholder={placeholder}
          disabled={disabled}
        />
        {field === 'aegPL' && status && (
          <StatusChip
            status={status}
            colorMapOverride={{
              'NOS Settled': '#6e6e6e',
              'Internal Forecast': '#e6e6e6',
            }}
            color={status === 'NOS Settled' ? 'white' : 'black'}
          />
        )}
      </TotalsCellContainer>
    );
  }

  return (
    <TextFieldCell
      variant="standard"
      value={disabled && value}
      defaultValue={!disabled && value}
      placeholder={placeholder}
      disabled={disabled}
      onKeyDown={(e) => {
        e.stopPropagation(); // Prevent MUI datagrid arrow keys from triggering
      }}
    />
  );
}
